// colors
$primary-color: #607d8b;
$dark-primary-color: #455a64;
$light-primary-color: #cfd8dc;

$accent-color: #9e9e9e;

$secondary-color: #757575;
$dark-secondary-color: #212121;
$light-secondary-color: #cfd8dc;

$background-color: #bdbdbd;
$box-shadow-color: rgba(170, 178, 197, 0.7);

$black-color: #000000;
$white-color: #ffffff;

$error-color: red;

// fonts
$main-font: 'Roboto', sans-serif;

// display sizes
$desktop: 1280px;
$tablet: 768px;
$mobile: 320px;

// timing
$timing: cubic-bezier(0.4, 0, 0.2, 1);
$duration: 250ms;
