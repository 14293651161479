@import '../../components/shared/variables';

.link {
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
  min-width: 64px;
  padding: 6px 16px;
  margin-left: 10px;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  background-color: $accent-color;
  border-radius: 4px;
  color: $white-color;
  text-transform: uppercase;
  transition: background-color $timing, box-shadow $timing, border $timing;
}

.link:hover,
.link:focus {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: $secondary-color;
}
