*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style: none;
}

a {
  font-style: normal;
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
}

img {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}
