.container {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 300;
  color: #fff;
}

.text {
  margin-right: 5px;
  margin-left: 5px;
}
