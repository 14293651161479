@import '../../components/shared/variables';

.navLink {
  display: inline-block;
  text-decoration: none;
  padding-right: 10px;
  font-size: 20px;
  font-weight: 300;
  color: $white-color;
}

.navLink:last-child {
  padding-right: 0;
}

.navLink:hover,
.navLink:focus {
  color: $light-secondary-color;
}

.activeNavLink {
  color: $light-secondary-color;
}
